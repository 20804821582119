<template>
  <div class="property-container">
    <!-- 物业合同 -->
    <!-- 头部选择框 -->
    <CSTabBar
      :tabbar="tabBar"
      :checked-tab="checkedTaBar"
      @changeTabBar="changeTabBar"
    />
    <template v-if="checkedTaBar == 1">
      <div class="filter-panel">
        <CSSelect i-width="36rpx" style="width: 230px; margin-right: 0">
          <el-date-picker
            type="datetime"
            placeholder="请选择开始时间"
            v-model="conditions.startTime"
            style="width: 214px"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            prefix-icon="el-icon-time"
            :editable="false"
          >
          </el-date-picker>
        </CSSelect>
        <span class="inline-gray"> </span>
        <CSSelect i-width="36rpx" style="width: 230px">
          <el-date-picker
            type="datetime"
            placeholder="请选择结束时间"
            v-model="conditions.endTime"
            style="width: 214px"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            prefix-icon="el-icon-time"
            :editable="false"
          >
          </el-date-picker>
        </CSSelect>
        <CSSelect>
          <select v-model="conditions.contractState">
            <option value="">合同状态不限</option>
            <option v-for="item in contractStates" :key="item.id" :value="item">
              {{ item.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect>
          <select v-model="conditions.billState">
            <option value="">账单周期不限</option>
            <option
              v-for="(item, index) in billStates"
              :key="index + 'BILL'"
              :value="item"
            >
              {{ item.name }}
            </option>
          </select>
        </CSSelect>
        <div
          style="
            vertical-align: middle;
            margin-bottom: 15px;
            display: inline-block;
          "
        >
          <input
            type="text"
            placeholder="搜索合同编号/业主/租客"
            v-model="conditions.queryInput"
            style="
              color: #000;
              width: 320px;
              height: 30px;
              padding-left: 10px;
              border-radius: 4px;
              border: 1px solid #979797;
              margin-right: 20px;
            "
          />
        </div>
        <button type="button" class="btn btn-primary btn-sm" @click="query()">
          查询
        </button>
      </div>
      <!-- 列表显示框 -->
      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <button
                class="btn btn-primary sticky-right"
                @click="addOwnerBtn()"
              >
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                添加物业服务合同
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>添加时间</th>
              <th>合同编号</th>
              <th>合同签订日</th>
              <th>合同状态</th>
              <th>关联房间</th>
              <th>建筑面积</th>
              <th>业主/租客</th>
              <th>计费标准</th>
              <th>账单周期</th>
              <th>账单日</th>
              <th>添加人</th>
              <th>操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="(item, index) in contractList" :key="index + 'con'">
              <td style="min-width: 140px">{{ item.createDate }}</td>
              <td class="heCss">
                {{ item.number }}
                <template v-if="item.oldId">
                  <div
                    style="width: 35px;font-size:1px;padding:2px"
                    :class="['cs-tag', 1 < 0 ? 'success' : 'warning']"
                    class="heCssW"
                  >
                    {{ "变更" }}
                  </div>
                </template>
              </td>
              <td>{{ item.signDate }}</td>
              <td>{{ contractStates[item.state - 1].name }}</td>
              <td>
                <span class="btn-active" @click="seeRoomBtn(item)">查看</span>
              </td>
              <td>{{ item.builtUpArea ? item.builtUpArea + "m²" : "-" }}</td>
              <td>{{ item.lesseeName }}</td>
              <td>{{ item.ruleCharging }}元/m²/月</td>
              <td>
                {{
                  billArr[-1 + item.billType] +
                  "-" +
                  billArrs[-1 + item.billPeriod]
                }}
              </td>
              <td>
                <span class="btn-active" @click="seeBillBtn(item)">查看</span>
              </td>
              <td>{{ item.createUser }}</td>
              <td>
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    :disabled="item.state == 3"
                    :class="[item.state==3?'disBtn':'']"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu" style="width: 76px">
                    <li @click="addOwnerBtn(item)">
                      <a>变更合同</a>
                    </li>
                    <li @click="deleteBtn(item)">
                      <a>终止合同</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </CSTable>
        <!-- 分页 -->
        <Pagination name="pagination" componentName="Pagination"></Pagination>
      </div>
      <GetHydropower
        name="getHydropower"
        componentName="GetHydropower"
      ></GetHydropower>
      <!-- 编辑合同信息 -->
      <EditPropertyContract
        name="EditPropertyContract"
        componentName="EditPropertyContract"
      ></EditPropertyContract>
      <!-- 终止物业服务合同 -->
      <DeleteOwnerOver></DeleteOwnerOver>
    </template>
    <template v-if="checkedTaBar == 2">
      <PropertyRules></PropertyRules>
    </template>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import CSTable from "@/components/common/CSTable";
import CSSelect from "@/components/common/CSSelect";
import GetHydropower from "@/components/GetHydropower";
import EditPropertyContract from "@/components/EditPropertyContract";
import PropertyRules from "@/views/PropertyRules";
import dayjs from "dayjs";
import {
  queryPropertyContractUrl,
  queryRoomByPCIdUrl,
  terminationPropertyContractUrl,
} from "@/requestUrl";
import Pagination from "@/components/Pagination.vue";
import DeleteOwnerOver from "@/components/DeleteOwnerOver.vue";
export default {
  data() {
    return {
      isDisab:false,
      contractList: [],
      checkedTaBar: 1,
      filterHeight: "",
      tabBar: {
        1: "物业服务合同",
        2: "物业费规则",
      },
      //搜索的表单数据
      conditions: {
        startTime: dayjs().subtract(1, "year").format("YYYY-MM-DD") + " 00:00",
        endTime: dayjs().format("YYYY-MM-DD") + " 23:59",
        queryInput: "",
        contractState: "", //合同状态
        billState: "", //账单状态
      },
      //合同状态
      contractStates: [
        { id: 1, name: "已签订" },
        { id: 2, name: "执行中" },
        { id: 3, name: "已终止" },
      ],
      //账单周期状态
      billStates: [
        { id: [1, 1], name: "固定-年" },
        { id: [1, 2], name: "固定-半年" },
        { id: [1, 3], name: "固定-季度" },
        { id: [1, 4], name: "固定-月" },
        { id: [2, 1], name: "顺延-年" },
        { id: [2, 2], name: "顺延-半年" },
        { id: [2, 3], name: "顺延-季度" },
        { id: [2, 4], name: "顺延-月" },
      ],
      billArr: ["固定", "顺延"],
      billArrs: ["年", "半年", "季度", "月"],
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  components: {
    CSTabBar,
    CSTable,
    CSSelect,
    GetHydropower,
    EditPropertyContract,
    PropertyRules,
    Pagination,
    DeleteOwnerOver,
  },
  created() {
    //回车查询事件
    window.addEventListener("keydown", this.queryDown);
    this.query();
  },
  methods: {
    changeTabBar(index) {
      this.checkedTaBar = +index;
      if (this.checkedTaBar == 2) {
        //取消回车查询事件
        window.removeEventListener("keydown", this.queryDown);
      } else {
        window.removeEventListener("keydown", this.queryDown);
        window.addEventListener("keydown", this.queryDown);

        this.query();
        this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
          this.pageParams.pageNo = pageNo;
          this.queryPage(this.pageParams.pageNo);
        });
        this.$vc.on(
          this.$route.path,
          "deleteTenantOn",
          "openOwnerModelOverOn",
          (item) => {
            this.terminationBtn(item);
          }
        );
        this.$vc.on(this.$router.path, "editQuery", () => {
          this.query();
        });
      }
    },
    //终止物业服务合同
    terminationBtn(item) {
      this.$fly
        .get(terminationPropertyContractUrl, {
          pcId: item.id,
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.$vc.toast("合同终止成功");
          this.query();
        });
    },
    //查询按钮
    query(page = this.pageParams.pageNo, pageSi = 10) {
      this.$fly
        .post(queryPropertyContractUrl, {
          regionCode: this.$vc.getCurrentRegion().code,
          startDate: this.conditions.startTime, //开始区间
          endDate: this.conditions.endTime, //结束时间
          state: this.conditions.contractState.id, //状态  1已签订 2执行中 3 已终止
          billType: this.conditions.billState.id
            ? this.conditions.billState.id[0]
            : "", //类型 1 固定 2顺延
          billPeriod: this.conditions.billState.id
            ? this.conditions.billState.id[1]
            : "", //1年 2半年 3季度 4月
          search: this.conditions.queryInput, //搜索框内容
          pageSize: pageSi, //页面大小
          pageNo: page, //页码
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          console.log("查询");
          if (page === 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: page,
              pageSize: pageSi,
            });
          }
          this.contractList = res.data.dates;
        });
    },
    //添加物业服务合同
    addOwnerBtn(item) {
      if (item) {
        this.$vc.emit(this.$route.path, "EditPropertyContract", {
          value: item,
        });
      } else {
        this.$vc.emit(this.$route.path, "EditPropertyContract", {});
      }
    },
    //查看房间
    async seeRoomBtn(item) {
      let arr = {};
      arr = await this.$fly.get(queryRoomByPCIdUrl, {
        pcId: item.id, //合同id
      });
      console.log(arr.data);
      this.$vc.emit("getHydropower", "number", {
        title: "查看房间",
        tiem: arr.data.map((item, index) => {
          return {
            no: item,
            id: index,
          };
        }),
      });
    },
    //查看账单日
    seeBillBtn(item) {
      this.$vc.emit("getHydropower", "number", {
        title: "查看账单日",
        tiem: item.billDays.map((item, index) => {
          return {
            id: index,
            no: item,
          };
        }),
      });
    },
    //终止合同
    deleteBtn(item) {
      this.$vc.emit(this.$route.path, "deleteTenant", "openOwnerModelOver", {
        id: item.id,
        title: "确定终止该合同",
      });
    },
    //回车查询
    queryDown(e) {
      if (e.keyCode == 13) {
        this.query();
      }
    },
    //分页查询
    queryPage(pageNo) {
      this.query(pageNo);
    },
  },
  mounted() {
    //终止合同
    this.$vc.on(
      this.$route.path,
      "deleteTenantOn",
      "openOwnerModelOverOn",
      (item) => {
        this.terminationBtn(item);
      }
    );
    //分页
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.pageParams.pageNo = pageNo;
      this.queryPage(this.pageParams.pageNo);
    });
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
    //修改信息完成后回调
    this.$vc.on(this.$router.path, "editQuery", () => {
      this.query();
    });
  },
  beforeDestroy() {
    //取消回车查询事件
    window.removeEventListener("keydown", this.queryDown);
  },
  deactivated() {
    //取消回车查询事件
    window.removeEventListener("keydown", this.queryDown);
    console.log('组件销毁');
  }
};
</script>

<style>
.btn-active {
  color: #1dafff;
  text-decoration: underline;
  cursor: pointer;
}
.heCss{
  position: relative;
}
.heCssW{
  position: absolute;
  top: 8px;
}
.disBtn{
  background-color: #cccccc !important;
  border: none;
  box-shadow: none !important;
}
.btn-primary:disabled{
  border-color: rgba(0, 0, 0, 0) !important;
}
</style>
